<div class="custom-header">
  <div class="logos-container" (click)="goToWelcomePage()">
    <!-- <img class="do-like-me" src="assets/logos/LogoDLike.svg" /> -->
    <img class="sales-guide" src="assets/logos/LogoSalesGuide.svg" />
  </div>
  <div class="list-menu-header">
    <div
      *ngFor="let link of links"
      class="link"
      [routerLink]="link.path"
      [fragment]="link?.fragment"
      [ngClass]="current === link?.fragment ? 'active-link' : ''"
      (click)="scrollToSmoothly(link?.fragment)"
    >
      {{ "GENERAL." + link.label | translate | uppercase }}
    </div>
  </div>
  <div class="burger-menu">
    <button type="button" (click)="openCloseMenu()" class="only-icon-button">
      <mat-icon>{{ showMenu ? "clear" : "menu" }}</mat-icon>
    </button>
  </div>
  <div class="user-info-container">
    <div class="user-info">
      <div class="ta-right">
        <div class="name">{{ user.name }} {{ user.surname }}</div>
        <div class="role">{{ "GENERAL." + userRole | translate }}</div>
        <div
          *ngIf="showProjectSelection && customerName"
          class="customer-and-project"
        >
          {{ customerName }}
          <!-- <span>&nbsp;{{ "PR-" + project.idProject }}&nbsp;</span> -->
          <span></span>
          &nbsp;
          {{ projectStatusEnum["PR_STATUS_" + project.idProjectStatus] }}
        </div>
      </div>
      <mat-icon [matMenuTriggerFor]="submenu" #submenuTrigger="matMenuTrigger">
        expand_more
      </mat-icon>
    </div>
    <mat-menu #submenu="matMenu" class="info-menu-header">
      <form
        *ngIf="showProjectSelection"
        [formGroup]="projectForm"
        (click)="$event.stopPropagation()"
        class="project-menu-form"
      >
        <mat-form-field appearance="standard">
          <mat-label>{{ "GENERAL.Customer" | translate }}</mat-label>
          <mat-select
            formControlName="customer"
            (selectionChange)="onCustomerChange()"
          >
            <mat-option *ngIf="customersLoading" [value]="project.idCustomer">
              {{ "GENERAL.Loading" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let customer of customers"
              [value]="customer.idCustomer"
            >
              {{ customer.businessName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>{{ "GENERAL.Project" | translate }}</mat-label>
          <mat-select
            formControlName="project"
            (selectionChange)="onProjectChange()"
          >
            <mat-option *ngIf="projectsLoading" value="loading">
              {{ "GENERAL.Loading" | translate }}
            </mat-option>
            <mat-option *ngIf="customerProjects?.length === 0" disabled>
              {{ "WELCOME.NoProjectsData" | translate }}
            </mat-option>
            <mat-optgroup
              *ngFor="let customerProject of customerProjects"
              [label]="'PR-' + customerProject.idProject"
            >
              <mat-option
                *ngFor="let projectStatus of customerProject.projectHistoryList"
                [value]="projectStatus"
              >
                {{ "PR-" + customerProject.idProject }}
                <span>&ensp;&#10095;&ensp;</span>
                {{
                  projectStatusEnum[
                    "PR_STATUS_" + projectStatus?.idProjectStatus
                  ]
                }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </form>
      <!-- <ng-container *ngFor="let role of user?.roles">
        <button
          *ngIf="role !== activeUserRole"
          mat-menu-item
          (click)="changeRole(role)"
        >
          {{ "ROLES." + role | translate }}
        </button>
      </ng-container> -->
      <div class="menu-links">
        <button type="button" mat-menu-item (click)="goTo('profile')">
          {{ "GENERAL.ProfileInformation" | translate }}
        </button>
        <button type="button" mat-menu-item (click)="goTo('support')">
          {{ "GENERAL.Support" | translate }}
        </button>
        <button type="button" mat-menu-item (click)="logout()">
          {{ "GENERAL.Logout" | translate }}
        </button>
      </div>
    </mat-menu>
    <div class="user-image">
      <img *ngIf="userImage" [src]="userImage" />
    </div>
  </div>
</div>
<div
  class="burger-menu-list"
  [ngClass]="[
    showMenu ? 'open' : 'closed',
    windowVariable.location.toString().includes('/projects') ? 'half' : ''
  ]"
>
  <div
    class="project-info"
    [ngClass]="
      !windowVariable.location.toString().includes('/ps') &&
      !windowVariable.location.toString().includes('/rap')
        ? 'd-none'
        : ''
    "
  >
    <form
      *ngIf="showProjectSelection"
      [formGroup]="projectForm"
      (click)="$event.stopPropagation()"
      class="project-menu-form"
    >
      <mat-form-field appearance="standard">
        <mat-label>{{ "GENERAL.Customer" | translate }}</mat-label>
        <mat-select
          formControlName="customer"
          (selectionChange)="onCustomerChange()"
        >
          <mat-option *ngIf="customersLoading" [value]="project.idCustomer">
            {{ "GENERAL.Loading" | translate }}
          </mat-option>
          <mat-option
            *ngFor="let customer of customers"
            [value]="customer.idCustomer"
          >
            {{ customer.businessName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>{{ "GENERAL.Project" | translate }}</mat-label>
        <mat-select
          formControlName="project"
          (selectionChange)="onProjectChange(); showMenu = false"
        >
          <mat-option *ngIf="projectsLoading" value="loading">
            {{ "GENERAL.Loading" | translate }}
          </mat-option>
          <mat-option *ngIf="customerProjects?.length === 0" disabled>
            {{ "WELCOME.NoProjectsData" | translate }}
          </mat-option>
          <mat-optgroup
            *ngFor="let customerProject of customerProjects"
            [label]="'PR-' + customerProject.idProject"
          >
            <mat-option
              *ngFor="let projectStatus of customerProject.projectHistoryList"
              [value]="projectStatus"
            >
              {{ "PR-" + customerProject.idProject }}
              <span>&ensp;&#10095;&ensp;</span>
              {{
                projectStatusEnum["PR_STATUS_" + projectStatus?.idProjectStatus]
              }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div
    class="menu-list"
    [ngClass]="
      !windowVariable.location.toString().includes('/ps') &&
      !windowVariable.location.toString().includes('/rap')
        ? 'd-none'
        : ''
    "
  >
    <div
      *ngFor="let link of links"
      class="menu-item"
      [routerLink]="link.path"
      [fragment]="link?.fragment"
      [ngClass]="current === link?.fragment ? 'active-link-mobile' : ''"
      (click)="scrollToSmoothly(link?.fragment); openCloseMenu()"
    >
      {{ "GENERAL." + link.label | translate | uppercase }}
    </div>
  </div>
  <div class="general-info">
    <div class="general-item" (click)="goTo('profile')">
      {{ "GENERAL.Profile" | translate }}
    </div>
    <div class="general-item" (click)="goTo('support')">
      {{ "GENERAL.Support" | translate }}
    </div>
    <div class="general-item" (click)="logout()">
      {{ "GENERAL.Logout" | translate }}
    </div>
  </div>
</div>
