<div class="bg-app ofl-container">
    <div class="ofl-body">
      <div class="card-container">
        <!-- <div class="logout-button">
          <button nz-button nzType="default" type="button" (click)="logout()">Logout</button>
        </div> -->
        <router-outlet></router-outlet>
      </div>
    </div>
</div>
  