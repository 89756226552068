import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ComponentFactoryResolver,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { animate } from '@angular/animations';
import { style } from '@angular/animations';
import { state } from '@angular/animations';
import { trigger } from '@angular/animations';
import { transition } from '@angular/animations';
import { ITableColumn } from '@app/core/model/utility';
import { newChartColors } from '@app/core/enum/colors';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements OnInit, OnChanges {
  value: string;
  highlightsConditions: any;

  showSubMenu: boolean = false;
  columnToSort: string = '';
  arrowSortDirection: string = null;
  oldData: any[];
  defaultPaginatorItemsPerPage: number = 5;

  paginatorOptions: any = {
    pageIndex: 0,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
  };

  chartColors: string[] = newChartColors;

  pageIndexForChartColor: number = 0;

  activeSort: Sort;

  @Input() total: any;
  @Input() loader: boolean;
  @Input() columns: ITableColumn[];
  @Input() data: any[];
  @Input() dataColor: string;
  @Input() columnsButtons: any;
  @Input() columnsButtonsWithHeading: any;
  @Input() columnCheckbox: any;
  @Input() paginator: boolean = true;
  @Input() specialSorting: boolean = false;
  @Input() rowBadgeNewElements: boolean = false;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = 5;
  @Input()
  set highlightsRowsConditions(data) {
    this.highlightsConditions = data;
  }
  get highlightsRowsConditions(): any {
    return this.highlightsConditions;
  }
  @Input()
  set searchValue(value: string) {
    this.value = value;
  }
  get searchValue(): string {
    return this.value;
  }

  @Output() pageIsChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('paginator') paginatorElement: MatPaginator;

  // @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  // @Input() columnsName: IColumnsName[];
  // @Input() displayedColumns: string[];
  // @Input() tableOptions: ITableOptions;
  // @Input() tableButtons: ITableButtons[];
  // @Input() tableMenuButtons: ITableButtons[];
  // @Input() tableSubMenuButtons: ITableButtons[];
  // @Input() loader: boolean = false;
  // // @Input() createButtons: () => IDisplayedButtons[];
  // @Input() parentTabName: string = null;
  // @Input() addContactButtonDisabled = false;
  // @Input() checkbox: any;

  // @Output() addContactEmitter: EventEmitter<any> = new EventEmitter<any>();

  // formats: any[] = [
  //   moment.ISO_8601,
  //   'DD/MM/YYYY',
  //   'DD/MM/YYYY - HH:mm'
  // ];

  // @ViewChild(MatPaginator, { static: false }) set contentPaginator(matPaginator: MatPaginator) {
  //   this.dataSource.paginator = matPaginator;
  // }
  // @ViewChild(MatSort, { static: false }) set contentSort(matSort: MatSort) {
  //   this.dataSource.sortingDataAccessor = (item, property) => {
  //     if (moment(item[property], this.formats, true).isValid()) {
  //       const stringDate: string = moment(item[property], 'DD/MM/YYYY').format();
  //       return Date.parse(stringDate);
  //     } else {
  //       return item[property].toLowerCase();
  //     }
  //   };
  //   this.dataSource.sort = matSort;
  // }

  // @ViewChild('input') inputTest: ElementRef<any>;

  // filterValue: string;

  // expanded: boolean = false;
  // @Input() collapse: false;
  // @ViewChildren('tableRow', { read: ViewContainerRef }) rowContainers;
  // expandedRow: number;
  // @Input() columnsNameToShow: number;
  // @Input() displayedColumnsToShow: number;

  constructor(
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    if (this.specialSorting) {
      this.data = this.data.map((item: any): any => ({
        ...item,
        sortByPresenceMarket:
          item?.idMarketType === 1 ? (item?.toIncrease ? 2 : 1) : 0,
        sortByPotential:
          item?.idMarketType > 2 ? (item?.idMarketType === 3 ? 2 : 1) : 0,
        sortByBenchmark: item?.benchmark
          ? this.workItemBenchmark(item?.benchmark)
          : 0,
      }));
    }
    // if (this.pageIndex > 0) {
    //   for (let step = 0; step < this.pageIndex; step++) {
    //     console.log(this.paginatorElement);
    //     this.paginatorElement.nextPage();
    //   }
    // }

    // this.columnsNameToShow =
    //   this.collapse ? this.columnsNameToShow ? this.columnsNameToShow : 8 : this.columnsName?.length;
    // this.displayedColumnsToShow =
    //   this.collapse ? this.displayedColumnsToShow ? this.displayedColumnsToShow : 10 : this.displayedColumns?.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue) {
      this.oldData = changes.data.currentValue;
      this.pageChanged({
        pageIndex: 0,
        pageSize: this.pageSize,
        length: this.oldData?.length,
      });
    }
    if (changes?.pageIndex?.currentValue) {
      setTimeout(() => {
        for (let step = 0; step < changes?.pageIndex?.currentValue; step++) {
          this.paginatorElement.nextPage();
        }
      }, 100);
    }
  }

  pageChanged(event: PageEvent): void {
    const data = [...this.oldData];
    let sortedData: any[];
    if (this.activeSort?.active) {
      sortedData = data.sort((a: any, b: any) => {
        const isAsc = this.activeSort.direction === 'asc';
        return (
          (a[this.activeSort.active] < b[this.activeSort.active] ? -1 : 1) *
          (isAsc ? 1 : -1)
        );
      });
    } else {
      sortedData = data;
    }
    this.data = sortedData.splice(
      event.pageIndex * event.pageSize,
      event.pageSize
    );
    // this.data = data.splice(event.pageIndex * event.pageSize, event.pageSize);
    this.pageIndexForChartColor = event.pageIndex;
    this.pageIsChanged.emit(event);
  }

  workItemBenchmark(item: any): number {
    let result: number = 0;
    let fav: number;
    let sfav: number;
    let splittedString: string[];
    if (item?.includes(',')) {
      splittedString = item?.split(',');
      splittedString?.forEach((bench: string) => {
        if (bench.includes('F') && !bench.includes('S')) {
          if (bench.includes('()')) {
            fav = parseInt(
              bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
              10
            );
          } else {
            fav = 1;
          }
        } else if (bench.includes('SF')) {
          if (bench.includes('()')) {
            sfav = parseInt(
              bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
              10
            );
          } else {
            sfav = 1;
          }
        } else {
          result = 0;
        }
      });
    } else {
      if (item?.includes('F') && !item?.includes('S')) {
        if (item?.includes('()')) {
          fav = parseInt(
            item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
            10
          );
        } else {
          fav = 1;
        }
      } else if (item?.includes('SF')) {
        if (item?.includes('()')) {
          sfav = parseInt(
            item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
            10
          );
        } else {
          sfav = 1;
        }
      } else {
        result = 0;
      }
    }
    if (fav) {
      result = fav + 1000;
    }
    if (!fav && sfav) {
      result = sfav + 10;
    }
    return result;
  }

  checkIfIcon(row: any, column: any): boolean {
    if (
      typeof row[column.name] === 'string' ||
      row[column.name] instanceof String
    ) {
      if (row[column.name]?.includes('icon')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  sortData(sort: Sort): any {
    this.activeSort = sort;
    const data = this.oldData.slice();
    if (!sort.active || sort.direction === '') {
      this.activeSort = null;
      this.data = data.splice(
        this.paginatorElement?.pageIndex * this.paginatorElement?.pageSize,
        this.paginatorElement?.pageSize
      );
      return;
    }
    const sortedData: any[] = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return (a[sort.active] < b[sort.active] ? -1 : 1) * (isAsc ? 1 : -1);
    });
    this.data = sortedData.splice(
      this.paginatorElement?.pageIndex * this.paginatorElement?.pageSize,
      this.paginatorElement?.pageSize
    );
  }

  sortByColumn(column: string): void {
    if (!this.oldData) {
      this.oldData = this.data.slice(0);
    }
    // NEW CODE
    // if (this.arrowSortDirection === 'expand_less') {
    //   this.arrowSortDirection = 'expand_more';
    //   this.data.sort((a, b) => {
    //     return (
    //       a.valueForSorting - b.valueForSorting || a.levelOfInterest - b.levelOfInterest
    //     );
    //   });
    //   return;
    // }
    // if (this.arrowSortDirection === 'expand_more') {
    //   this.arrowSortDirection = 'expand_less';
    //   this.data.sort((a, b) => {
    //     return (
    //       b.valueForSorting - a.valueForSorting || b.levelOfInterest - a.levelOfInterest
    //     );
    //   });
    //   return;
    // }
    // OLD CODE
    this.columnToSort = column;
    if (this.arrowSortDirection === null) {
      this.arrowSortDirection = 'expand_less';
      this.data.sort((a, b): number => {
        if (a[column] === null) {
          return 1;
        }
        if (b[column] === null) {
          return -1;
        }
        // Check if column is a Date value type
        if (
          moment(a[column], 'DD/MM/YYYY', true).isValid() ||
          moment(a[column], 'DD/MM/YYYY - HH:mm', true).isValid()
        ) {
          let dateWithHour: boolean = a[column].includes('-') ? true : false;
          if (a[column].includes('-')) {
            dateWithHour = true;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') ===
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') ===
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return 0;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') <
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') <
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return -1;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') >
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') >
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return 1;
          }
        } else {
          if (a[column] === b[column]) {
            return 0;
          }
          if (a[column] < b[column]) {
            return -1;
          }
          if (a[column] > b[column]) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
      return;
    }
    if (this.arrowSortDirection === 'expand_less') {
      this.arrowSortDirection = 'expand_more';
      this.data.sort((a, b) => {
        if (a[column] === null) {
          return 1;
        }
        if (b[column] === null) {
          return -1;
        }
        // Check if column is a Date value type
        if (
          moment(a[column], 'DD/MM/YYYY', true).isValid() ||
          moment(a[column], 'DD/MM/YYYY - HH:mm', true).isValid()
        ) {
          let dateWithHour: boolean;
          if (a[column].includes('-')) {
            dateWithHour = true;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') ===
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') ===
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return 0;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') <
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') <
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return 1;
          }
          if (
            dateWithHour
              ? moment(a[column], 'DD/MM/YYYY - HH:mm') >
                moment(b[column], 'DD/MM/YYYY - HH:mm')
              : moment(a[column], 'DD/MM/YYYY') >
                moment(b[column], 'DD/MM/YYYY')
          ) {
            return -1;
          }
        } else {
          if (a[column] === b[column]) {
            return 0;
          }
          if (a[column] < b[column]) {
            return 1;
          }
          if (a[column] > b[column]) {
            return -1;
          }
        }
        return 0;
      });
      return;
    }
    if (this.arrowSortDirection === 'expand_more') {
      this.arrowSortDirection = null;
      this.data = this.oldData.slice(0);
      return;
    }

    // if (this.selectedColumnForSorting === columnName) {
    //   if (this.arrowSortDirection === 'expand_less') {
    //     this.arrowSortDirection = 'expand_more';
    //     if (columnName === 'idMarketType') {
    //       this.data.sort((a, b) => {
    //         return (
    //           a.sortByPresenceMarket - b.sortByPresenceMarket || a.levelOfInterest - b.levelOfInterest
    //         );
    //       });
    //     } else if (columnName === 'marketTypeNameId') {
    //       this.data.sort((a, b) => {
    //         return (
    //           a.sortByPotential - b.sortByPotential || a.levelOfInterest - b.levelOfInterest
    //         );
    //       });
    //     } else if (columnName === 'benchmark') {
    //       this.data.sort((a, b) => {
    //         return (
    //           a.sortByBenchmark - b.sortByBenchmark
    //         );
    //       });
    //     } else {
    //       this.data.sort((a, b) => {
    //         if (columnType === 'string') {
    //           if ((a[columnName] || '')  > (b[columnName] || '')) { return -1; }
    //           if ((a[columnName] || '') < (b[columnName] || '')) { return 1; }
    //           return 0;
    //         }
    //         if (columnType === 'number') {
    //           if (parseInt(a[columnName] ? a[columnName] : 0, 10) > parseInt(b[columnName] ? b[columnName] : 0, 10)) { return -1; }
    //           if (parseInt(a[columnName] ? a[columnName] : 0, 10) < parseInt(b[columnName] ? b[columnName] : 0, 10)) { return 1; }
    //           return 0;
    //         }
    //       });
    //     }
    //     return;
    //   }
    //   if (this.arrowSortDirection === 'expand_more') {
    //     this.arrowSortDirection = 'expand_less';
    //     if (columnName === 'idMarketType') {
    //       this.data.sort((a, b) => {
    //         return (
    //           b.sortByPresenceMarket - a.sortByPresenceMarket || b.levelOfInterest - a.levelOfInterest
    //         );
    //       });
    //       return;
    //     } else if (columnName === 'marketTypeNameId') {
    //       this.data.sort((a, b) => {
    //         return (
    //           b.sortByPotential - a.sortByPotential || b.levelOfInterest - a.levelOfInterest
    //         );
    //       });
    //       return;
    //     } else if (columnName === 'benchmark') {
    //       this.data.sort((a, b) => {
    //         return (
    //           b.sortByBenchmark - a.sortByBenchmark
    //         );
    //       });
    //       return;
    //     } else {
    //       this.data.sort((a, b) => {
    //         if (columnType === 'string') {
    //           if ((a[columnName] || '') < (b[columnName] || '')) { return -1; }
    //           if ((a[columnName] || '') > (b[columnName] || '')) { return 1; }
    //           return 0;
    //         }
    //         if (columnType === 'number') {
    //           if (parseInt(a[columnName] ? a[columnName] : 0, 10) < parseInt(b[columnName] ? b[columnName] : 0, 10)) { return -1; }
    //           if (parseInt(a[columnName] ? a[columnName] : 0, 10) > parseInt(b[columnName] ? b[columnName] : 0, 10)) { return 1; }
    //           return 0;
    //         }
    //         return 0;
    //       });
    //       return;
    //     }
    //   }
    // } else {
    //   this.selectedColumnForSorting = columnName;
    //   this.arrowSortDirection = 'expand_more';
    //   if (columnName === 'idMarketType') {
    //     this.data.sort((a, b) => {
    //       return (
    //         a.sortByPresenceMarket - b.sortByPresenceMarket || a.levelOfInterest - b.levelOfInterest
    //       );
    //     });
    //     return;
    //   } else if (columnName === 'marketTypeNameId') {
    //     this.data.sort((a, b) => {
    //       return (
    //         a.sortByPotential - b.sortByPotential || a.levelOfInterest - b.levelOfInterest
    //       );
    //     });
    //     return;
    //   } else if (columnName === 'benchmark') {
    //     this.data.sort((a, b) => {
    //       return (
    //         a.sortByBenchmark - b.sortByBenchmark
    //       );
    //     });
    //     return;
    //   } else {
    //     this.data.sort((a, b) => {
    //       if (columnType === 'string') {
    //         if ((a[columnName] || '') > (b[columnName] || '')) { return -1; }
    //         if ((a[columnName] || '') < (b[columnName] || '')) { return 1; }
    //         return 0;
    //       }
    //       if (columnType === 'number') {
    //         if (parseInt(a[columnName] ? a[columnName] : 0, 10) > parseInt(b[columnName] ? b[columnName] : 0, 10)) { return -1; }
    //         if (parseInt(a[columnName] ? a[columnName] : 0, 10) < parseInt(b[columnName] ? b[columnName] : 0, 10)) { return 1; }
    //         return 0;
    //       }
    //       return 0;
    //     });
    //   }
    // }
  }

  // applyFilter(event: Event): void {
  //   this.filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = this.filterValue.trim().toLowerCase();
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

  // addContact(): void {
  //   this.addContactEmitter.emit();
  // }

  // insertComponent(index: number): void {
  //   this.expanded = this.expandedRow !== 0;
  //   if (this.expandedRow != null) {
  //     // clear old content
  //     this.rowContainers.toArray()[this.expandedRow].clear();
  //   }

  //   if (this.expandedRow === index) {
  //     this.expandedRow = null;
  //   } else {
  //     const container = this.rowContainers.toArray()[index];
  //     const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(TableRowDetailsComponent);
  //     const inlineComponent = container.createComponent(factory);

  //     inlineComponent.instance.columnsName = this.columnsName;
  //     inlineComponent.instance.element = this.dataSource.data[index];
  //     inlineComponent.instance.columnsToShow = this.columnsNameToShow;
  //     this.expandedRow = index;
  //   }
  // }
}
