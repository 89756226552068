import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiRootUrl } from '@app/core/enum/api';
import { map } from 'rxjs/operators';
import { Storage } from '@app/utility/storage';
import { StorageKeys } from '@app/core/enum/storage';
import { ICustomer, ICustomers } from '@app/core/model/customer';
import { IProduct } from '@app/core/model/product';
import { ISwotUsp } from '@app/core/model/swot-usp';
import { ICountry } from '@app/core/model/address';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  /**
   * @description API to get all the projects of a customer
   * @param idCustomer Id of the logged customer
   * @returns Array of projects
   */
  getAllProjectsByCustomer(idCustomer: number): Observable<any> {
    return this.http
      .get<any>(`${apiRootUrl}/project/customer/${idCustomer}`)
      .pipe();
  }

  /**
   * @description API to get the customer that logged in
   * @returns Customer
   */
  getCurrentCustomer(): Observable<ICustomer> {
    return this.http.get<ICustomer>(`${apiRootUrl}/customer/usercustomer`).pipe(
      map((customer: any): any => {
        Storage.set(StorageKeys.user, {
          ...Storage.get(StorageKeys.user),
          idCustomer: customer.idCustomer,
        });
        return customer;
      })
    );
  }

  /**
   * @description API to get all the customers relative to logged user
   * @returns Array of customers
   */
  getAllCustomersByUser(): Observable<ICustomers> {
    return this.http.get<ICustomers>(`${apiRootUrl}/customer/dlike/all?frontOffice=true`).pipe();
  }

  getCustomerLogo(): Observable<any> {
    return this.http.get<any>(`${apiRootUrl}/customer/public`).pipe();
  }

  /**
   * @description API to get all countries
   */
  getAllCountries(): Observable<ICountry[]> {
    return this.http
      .get<ICountry[]>(`${apiRootUrl}/foreignMarket/getAllCountry`)
      .pipe();
  }

  getCustomerGeneralInfoByCustomer(idCustomer: number): Observable<ICustomer> {
    return this.http
      .get<ICustomer>(`${apiRootUrl}/customer/${idCustomer}`)
      .pipe();
  }

  getCustomerInformationByCustomer(idCustomer: number): Observable<any> {
    return this.http
      .get<any>(
        `${apiRootUrl}/customerInformation/getByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  /**
   * @description Call GET `product/getProductByIdCustomer/:idCustomer` API to get all the productsServices of the customer
   * @param id id of Customer
   */
  getProductsByIdCustomer(
    idCustomer: number | string,
    forFrontOffice?: boolean
  ): Observable<IProduct[]> {
    return this.http
      .get<IProduct[]>(
        `${apiRootUrl}/product/getByIdCustomer/${idCustomer}?frontOffice=true`
      )
      .pipe();
  }

  getSwotUspByCustomer(idCustomer: number | string): Observable<ISwotUsp> {
    return this.http
      .get<ISwotUsp>(`${apiRootUrl}/swotusp/getByIdCustomer/${idCustomer}`)
      .pipe();
  }

  getPSCommentsByIdProject(idProject: number): Observable<any> {
    return this.http
      .get<any>(`${apiRootUrl}/customer/description/project/${idProject}`)
      .pipe();
  }
}
