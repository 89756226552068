import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { it_IT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SharedModule } from './core/modules/shared.module';
import { JwtModule } from '@auth0/angular-jwt';
import { allowedDomains, headerName } from '@core/enum/api';
import { authScheme, disallowedRoutes, tokenGetter } from '@utility/token';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LangTranslationLoader } from 'src/app/utility/lenguage.loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotifierModule } from 'angular-notifier';
import { ConfigurationService } from './core/services/configuration/configuration.service';

registerLocaleData(it);
registerLocaleData(en);

export function HttpLoaderFactory(
  httpClient: HttpClient
): LangTranslationLoader {
  return new LangTranslationLoader(httpClient);
}

// Configuration Initializer
const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig({
      position: {
        vertical: {
          position: 'top',
          distance: 5
        },
        horizontal: {
          position: 'right'
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: 3000,
        onClick: 'hide',
        showDismissButton: true,
        stacking: 4
      }
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    SharedModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        headerName,
        authScheme,
        allowedDomains,
        disallowedRoutes: disallowedRoutes(),
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxChartsModule,
  ],
  exports: [SharedModule],
  providers: [
    { provide: NZ_I18N, useValue: it_IT },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
