export const StorageKeys = {
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
    tokenShema: 'auth_schema',
    tokenDate: 'issue_date',
    expireToken: 'expires_in',
    user: 'session_user',
    project: 'session_project',
    customer: 'session_customer'
};
