import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SiteLayoutComponent } from '@app/components/layouts/site-layout/site-layout.component';
import { NgZorroAntdModule } from '@core/modules/ng-zorro-antd.module';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { HttpRefreshInterceptorProvider } from '../interceptors/refresh-token/refresh-token.interceptor';
import { ErrorHandlerProvider } from '../services/error-handler.service';
import { HttpRequestInterceptorProvider } from '../interceptors/http/http.interceptor';
import { AppLayoutComponent } from '@app/components/layouts/app-layout/app-layout.component';
import { SidebarMenuComponent } from '@app/components/sidebar-menu/sidebar-menu.component';
import { HeaderComponent } from '../../components/header/header.component';
import { ChartsModule } from 'ng2-charts';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LangTranslationLoader } from 'src/app/utility/lenguage.loader';
import { CloseOnClickOutsideDirective } from '../directives/close-on-click-outside.directive';
import { NgxEchartsModule } from 'ngx-echarts';
import { MaterialDesignModule } from './material-design.module';
import { TableComponent } from '@app/components/table/table.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function HttpLoaderFactory(
  httpClient: HttpClient
): LangTranslationLoader {
  return new LangTranslationLoader(httpClient);
}

@NgModule({
  declarations: [
    SiteLayoutComponent,
    AppLayoutComponent,
    SidebarMenuComponent,
    HeaderComponent,
    CloseOnClickOutsideDirective,
    TableComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialDesignModule,
    NgZorroAntdModule,
    PerfectScrollbarModule,
    ChartsModule,
    TranslateModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    JwtInterceptor,
    HttpRefreshInterceptorProvider,
    HttpRequestInterceptorProvider,
    ErrorHandlerProvider,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialDesignModule,
    NgZorroAntdModule,
    SiteLayoutComponent,
    AppLayoutComponent,
    SidebarMenuComponent,
    HeaderComponent,
    PerfectScrollbarModule,
    ChartsModule,
    TranslateModule,
    CloseOnClickOutsideDirective,
    NgxEchartsModule,
    TableComponent,
  ],
})
export class SharedModule {}
