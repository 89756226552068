import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { apiRootUrl } from '@app/core/enum/api';
import { Path } from '@app/core/enum/path';
import { StorageKeys } from '@app/core/enum/storage';
import { LoginResponse, IUser, SignUpRequest } from '@app/core/model/user';
import { Storage } from '@app/utility/storage';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private router: Router
  ) {}

  signIn(username: string, password: string): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.getToken(username, password).subscribe({
        complete: () => {
          this.getProfile().subscribe({
            complete: () => {
              subscriber.complete();
            },
          });
        },
        error: () => {
          subscriber.error();
        },
      });
    });
  }

  getToken(username: string, password: string): Observable<LoginResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    });
    return this.http
      .post<LoginResponse>(`${apiRootUrl}/account/access_token`, null, {
        headers,
      })
      .pipe(
        map((token: LoginResponse) => {
          this.setTokenInStorage(token);
          return token;
        })
      );
  }

  setTokenInStorage(token: LoginResponse): void {
    Storage.set(StorageKeys.accessToken, token.access_token);
    Storage.set(StorageKeys.expireToken, token.expires_in);
    Storage.set(StorageKeys.tokenDate, token.issue_date);
    Storage.set(StorageKeys.tokenShema, token.token_type);
    Storage.set(StorageKeys.refreshToken, token.refresh_token);
  }

  refreshToken(): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${apiRootUrl}/account/refresh_token`,
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        params: {
          access_token: Storage.get(StorageKeys.accessToken),
          refresh_token: Storage.get(StorageKeys.refreshToken),
        },
      }
    );
  }

  getProfile(): Observable<IUser> {
    return this.http.get<IUser>(`${apiRootUrl}/user/profile`).pipe(
      map((user: IUser): IUser => {
        this.setUserInStorage(user);
        return user;
      })
    );
  }

  setUserInStorage(sessionUser: IUser): void {
    Storage.set(StorageKeys.user, sessionUser);
  }

  isAuthenticated(): boolean {
    const token = Storage.get(StorageKeys.accessToken);
    if (!token) {
      return false;
    }
    return true;
  }

  logout(): void {
    Storage.cleanAll();
    this.zone.run(() => {
      this.router.navigate([`${Path.Auth}/${Path.SignIn}`]);
    });
  }

  /**
   * @description Call the PUT `account/signup` API, Sign up the user
   * @param body the body of signup request
   */
  confirmSignUp(body: SignUpRequest): Observable<any> {
    return this.http
      .put(`${apiRootUrl}/account/confirmsignup`, body)
      .pipe();
  }
}
