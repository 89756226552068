import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { Path } from '@core/enum/path';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: Path.Welcome },
  {
    path: Path.Welcome,
    loadChildren: () =>
      import('./features/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: Path.Auth,
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Path.PS,
    loadChildren: () =>
      import('./features/ps/ps.module').then((m) => m.PsModule),
    canActivate: [AuthGuard],
  },
  {
    path: Path.RAP,
    loadChildren: () =>
      import('./features/rap/rap.module').then((m) => m.RapModule),
    canActivate: [AuthGuard],
  },
  { path: Path.NotFound, component: NotFoundComponent },
  { path: Path.Unauthorized, component: NotFoundComponent },
  { path: '**', pathMatch: 'full', redirectTo: Path.NotFound },
];

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
