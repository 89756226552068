import { Storage } from './storage';
import { StorageKeys } from '@core/enum/storage';
import { noAuthApiRoutes, apiRootUrl } from '@core/enum/api';

/**
 * This functione retrive the from storage
 * @param request HttpRequest
 */
export const tokenGetter = () => {
  return Storage.get(StorageKeys.accessToken);
};

/**
 * This function retrive the auth schema
 * (mostely bearer)
 * @param request HttpRequest
 */
export const authScheme = () => {
  return `${Storage.get(StorageKeys.tokenShema)} `;
};

/**
 * Map the noAuthApiRoute array and and concat the
 * apiRootUrl to every route
 */
export const disallowedRoutes = (): Array<string|RegExp> => {
  return noAuthApiRoutes.map(
    (route): string | RegExp => {
      return `${apiRootUrl}${route}`;
    });
};
