// type PR_STATUS_PS_VERSION = {
//   [key: string]: string; // this is index signature
// };

export enum PR_STATUS_PS_VERSION  {
  PR_STATUS_5 = 'PS1',
  PR_STATUS_6 = 'PS1',
  PR_STATUS_9 = 'PS2',
  PR_STATUS_10 = 'PS3',
  PR_STATUS_11 = 'PS4',
  PR_STATUS_12 = 'PS5',
  PR_STATUS_13 = 'PS6',
  PR_STATUS_15 = 'PS2',
  PR_STATUS_16 = 'PS3',
  PR_STATUS_17 = 'PS4',
  PR_STATUS_18 = 'PS5',
  PR_STATUS_19 = 'PS6',
};

type PS_VERSION_PR_STATUS = {
  [key: string]: number; // this is index signature
};

export const PS_VERSION_PR_STATUS: PS_VERSION_PR_STATUS = {
  PS: 5,
  RAP_1: 9,
  RAP_2: 10,
  RAP_3: 11,
  RAP_4: 12,
  RAP_5: 13,
};
