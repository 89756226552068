import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
  ) { }

  handleError(catchedErr: any): any {
    try {
      if (catchedErr instanceof ErrorEvent) {
        console.error('This is an error event instance');
        console.error(catchedErr);
      }
      if (catchedErr instanceof Object) {
        console.error('This is an error event instance');
        console.error(catchedErr);
      }
    } catch (err) {
      console.error(err);
    }
  }

}

export const ErrorHandlerProvider = [{
  provide: ErrorHandler,
  useClass: ErrorHandlerService
}];
