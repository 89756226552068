import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BackEndResponse } from '@app/core/enum/utility';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private notification: NzNotificationService,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
      map((response: HttpEvent<unknown>) => {
        if (response instanceof HttpResponse ) {
        }
        return response;
      }),
      finalize(() => {
      }),
      catchError((catchedErr) => {
        if (catchedErr instanceof HttpErrorResponse) {
          const error: BackEndResponse = catchedErr.error?.errors || null;
          if (catchedErr.status === 0) {
            this.notification.error(
              'Errore', // notification title
              'Servizio non disponibile' // notification description
            );
            this.ngZone.run(() => {
              this.router.navigate(['auth/sign-in']);
            });
          }
          console.error('This is an HTTP error instance');
          console.error(catchedErr);
        }
        return throwError(catchedErr);
      })
    );
  }
}

export const HttpRequestInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpRequestInterceptor,
  multi: true,
};
