import { Directive, EventEmitter, ElementRef, HostListener, Output } from '@angular/core';


@Directive({
  selector: '[appCloseOnClickOutside]'
})
export class CloseOnClickOutsideDirective {

  @Output() appCloseOnClickOutside = new EventEmitter<MouseEvent>();

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.appCloseOnClickOutside.emit(event);
    }
  }

}
