import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '@app/utility/storage';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public appConfig : any;
  private jsonURL = 'assets/config/appConfig.json';

  constructor(public http: HttpClient) { }


  getJSON(): Observable<any> {
    return this.http.get(this.jsonURL);
  }

  loadAppConfig() {    
    return new Promise<any>((resolve, reject) => {
      try {
        this.getJSON().subscribe(data => {
          return resolve(this.setConfig(data));
        });
      } catch (e) {
        reject(e);
        throw new Error('error');
      }
    });
  }

  setConfig(data) {
    this.appConfig = data;
    Storage.set("protocol", `${data.protocol}`)
    Storage.set("domain", `${data.domain}`)
    Storage.set("apiContext", `${data.apiContext}`)
    return data;
  }

  getConfig() {
    return this.appConfig;
  }
}
