import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';
import { AuthService } from '@app/features/auth/services/auth.service';
import { apiRootUrl } from '@app/core/enum/api';
import { mergeMap } from 'rxjs/operators';
import { LoginResponse } from '@app/core/model/user';
import { BackEndResponse } from '@app/core/enum/utility';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private jwtInterceptor: JwtInterceptor,
    private jwtService: JwtHelperService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.jwtInterceptor.isDisallowedRoute(request) || !this.jwtService.isTokenExpired()) {
      return next.handle(request);
    }
    if (request.url.includes(apiRootUrl) && this.jwtService.isTokenExpired()) {
      return this.refreshToken().pipe(
        mergeMap(() => {
          return this.jwtInterceptor.intercept(request, next);
      }));
    }
    return next.handle(request);
  }

  refreshToken(): Observable<LoginResponse> {
    const refreshSubject = new ReplaySubject<LoginResponse>(1);
    refreshSubject.subscribe((resp: LoginResponse) => {
      this.authService.setTokenInStorage(resp);
    }, (err: BackEndResponse): HttpResponse<any> => {
      const error = new HttpErrorResponse({
        status: 401,
        error: {
          error: 'Unauthorized',
        }
      });
      throw error;
    });
    this.authService.refreshToken().subscribe(refreshSubject);
    return refreshSubject;
  }
}

export const HttpRefreshInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RefreshTokenInterceptor,
  multi: true,
};
