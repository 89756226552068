import { Storage } from "@app/utility/storage";
// import { environment } from "src/environments/environment";

// export const { DOMAIN, PROTOCOL, API_CONTEXT } = environment;
export const protocol = Storage.get("protocol");
export const domain = Storage.get("domain");
export const apiContext = Storage.get("apiContext");

// export const apiRootUrl = `${PROTOCOL}://${DOMAIN}${API_CONTEXT}`;
export const apiRootUrl = `${protocol}://${domain}/${apiContext}`;

export const headerName = 'Authorization';
// export const allowedDomains = [DOMAIN];
export const allowedDomains = [domain];
export const noAuthApiRoutes = [ '/account/access_token', '/account/refresh_token', '/account/confirmsignup', '/account/passwordReset' ];