<nz-layout class="layout">
  <div style="position: sticky; top: 0; z-index: 20">
    <app-header></app-header>
  </div>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
  <div class="footer">
    <div>Powered by</div>
    <img class="do-like-me" src="assets/logos/LogoDLike.svg" />
  </div>
</nz-layout>
