export const ColorsString = [
  // Standard HTML colors RGBA (14)
  'rgba(0,0,255,0.5)',
  'rgba(255,0,0,0.5)',
  'rgba(0,128,0,0.5)',
  'rgba(255,255,0,0.5)',
  'rgba(128,0,0,0.5)',
  'rgba(0,255,255,0.5)',
  'rgba(255,0,255,0.5)',
  'rgba(0,255,0,0.5)',
  'rgba(128,128,128,0.5)',
  'rgba(0,0,128,0.5)',
  'rgba(128,0,128,0.5)',
  'rgba(128,128,0,0.5)',
  'rgba(192,192,192,0.5)',
  'rgba(0,128,128,0.5)',
];

export const ColorsObject = [
  {
    name: 'blue',
    hex: '#0000ff',
    rgb: 'rgba(0,0,255,0.5)',
  },
  {
    name: 'red',
    hex: '#ff0000',
    rgb: 'rgba(255,0,0,0.5)',
  },
  {
    name: 'green',
    hex: '#008000',
    rgb: 'rgba(0,128,0,0.5)',
  },
  // {
  //   name: 'yellow', hex: '#ffff00', rgb: 'rgba(255,255,0,0.5)'
  // },
  {
    name: 'maroon',
    hex: '#800000',
    rgb: 'rgba(128,0,0,0.5)',
  },
  {
    name: 'aqua',
    hex: '#00ffff',
    rgb: 'rgba(0,255,255,0.5)',
  },
  {
    name: 'fuchsia',
    hex: '#ff00ff',
    rgb: 'rgba(255,0,255,0.5)',
  },
  {
    name: 'lime',
    hex: '#00ff00',
    rgb: 'rgba(0,255,0,0.5)',
  },
  {
    name: 'gray',
    hex: '#808080',
    rgb: 'rgba(128,128,128,0.5)',
  },
  {
    name: 'navy',
    hex: '#000080',
    rgb: 'rgba(0,0,128,0.5)',
  },
  {
    name: 'purple',
    hex: '#800080',
    rgb: 'rgba(128,0,128,0.5)',
  },
  {
    name: 'olive',
    hex: '#808000',
    rgb: 'rgba(128,128,0,0.5)',
  },
  {
    name: 'silver',
    hex: '#c0c0c0',
    rgb: 'rgba(192,192,192,0.5)',
  },
  {
    name: 'teal',
    hex: '#008080',
    rgb: 'rgba(0,128,128,0.5)',
  },
  /* Standard X11 Colors (14) */
  {
    name: 'aquamarine',
    hex: '#7fffd4',
    rgb: 'rgba(127,255,212,0.5)',
  },
  {
    name: 'orange',
    hex: '#ffa500',
    rgb: 'rgba(255,165,0,0.5)',
  },
  {
    name: 'yellowgreen',
    hex: '#9acd32',
    rgb: 'rgba(154,205,50,0.5)',
  },
  {
    name: 'goldenrod',
    hex: '#daa520',
    rgb: 'rgba(218,165,32,0.5)',
  },
  {
    name: 'peru',
    hex: '#cd853f',
    rgb: 'rgba(205,133,63,0.5)',
  },
  {
    name: 'skyblue',
    hex: '#87ceeb',
    rgb: 'rgba(135,206,235,0.5)',
  },
  {
    name: 'salmon',
    hex: '#fa8072',
    rgb: 'rgba(250,128,114,0.5)',
  },
  {
    name: 'lightseagreen',
    hex: '#20b2aa',
    rgb: 'rgba(32,178,170,0.5)',
  },
  {
    name: 'moccasin',
    hex: '#ffe4b5',
    rgb: 'rgba(255,228,181,0.5)',
  },
  {
    name: 'chocolate',
    hex: '#d2691e',
    rgb: 'rgba(210,105,30,0.5)',
  },
  {
    name: 'dodgerblue',
    hex: '#1e90ff',
    rgb: 'rgba(30,144,255,0.5)',
  },
  {
    name: 'lightpink',
    hex: '#ffb6c1',
    rgb: 'rgba(255,182,193,0.5)',
  },
  {
    name: 'limegreen',
    hex: '#32cd32',
    rgb: 'rgba(50,205,50,0.5)',
  },
  {
    name: 'rosybrown',
    hex: '#bc8f8f',
    rgb: 'rgba(188,143,143,0.5)',
  },
];

export const ChartColorScheme = [
  'rgba(0,0,255,0.6)',
  'rgba(255,0,0,0.6)',
  'rgba(0,128,0,0.6)',
  // 'rgba(255,255,0,0.6)',
  'rgba(128,0,0,0.6)',
  'rgba(0,255,255,0.6)',
  'rgba(255,0,255,0.6)',
  'rgba(0,255,0,0.6)',
  'rgba(128,128,128,0.6)',
  'rgba(0,0,128,0.6)',
  'rgba(128,0,128,0.6)',
  'rgba(128,128,0,0.6)',
  'rgba(192,192,192,0.6)',
  'rgba(0,128,128,0.6)',
];

export const ChartColors = [
  '#41DCDC',
  '#F8E23F',
  '#7582EB',
  '#F79245',
  '#267EE3',
  '#C019D2',
  '#B088F2',
  '#F36F68',
  '#278EA0',
  '#EFA02A',
  '#F3689B',
  '#FBB570',
  '#69D894',
  '#846100',
  '#5651DB',
  '#F68851',
];

export const ColorsForFunnelChart = ['#bde7f4', '#25b0db', '#7cd0e9'];
export const newChartColors = [
  '#25B0DB',
  '#73CC34',
  '#BF6ECE',
  '#FFA54B',
  '#7582EB',
  '#F8E23F',
  '#41DCDC',
  '#D96F1D',
  '#DD2156',
  '#424242',
  '#5D7C8C',
  '#7B5446',
  '#CDDC3A',
  '#009688',
  '#2196F5',
  '#3F51B6',
  '#9C28B4',
  '#890A37',
  '#1C1C1C',
  '#D9FFFC',
];
export const contactStatusColors = [
  '#73CC34',
  '#80E0FD',
  '#25B0DB',
  '#FFA54B',
  '#DD2156',
  '#84062A',
  '#430214',
  '#000000',
];

// #6EB0B0 > #41DCDC
// #E4D354 > #F8E23F
// #A4A7BC > #7582EB
// #DA9662 > #F79245
// #4C82BF > #267EE3
// #B0A53D > #D2BF19
// #B394E4 > #B088F2
// #DA8580 > #F36F68
// #457B84 > #278EA0
// #CC984C > #EFA02A
// #DA80A1 > #F3689B
// #E4B484 > #FBB570
// #86BC9B > #69D894
// #846100 > #846100
// #7472BC > #5651DB
// #E48E64 > #F68851
