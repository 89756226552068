import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { apiRootUrl } from '@app/core/enum/api';
import { Path } from '@app/core/enum/path';
import { PR_STATUS_PS_VERSION } from '@app/core/enum/status';
import { StorageKeys } from '@app/core/enum/storage';
import { Storage } from '@app/utility/storage';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  idProject: string;
  projectStatus: number;
  writtenBy: string;
  reason: string;
  customerLogoPath: string;
  psMenuItems: any[];
  rapMenuItems: any;
  expandMenuItem: boolean[] = [false, false, false];
  windowVariable: any = window;


  constructor(
    private router: Router,
    private el: ElementRef
  ) {
    if (
      window.location.hash === '#marketsAndChannels' ||
      window.location.hash === '#contacts' ||
      window.location.hash === '#offersAndOrders' ||
      window.location.hash === '#performance'
    ) {
      this.expandMenuItem[0] = true;
    } else if (
      window.location.hash === '#marketsBeingAnalysed' ||
      window.location.hash === '#swotUspAnalysis' ||
      window.location.hash === '#sizingOfMarkets' ||
      window.location.hash === '#competitorCompanyPositioning'
    ) {
      this.expandMenuItem[1] = true;
    } else if (
      window.location.hash === '#revenueTargets' ||
      window.location.hash === '#actionPlan'
    ) {
      this.expandMenuItem[2] = true;
    }
  }

  ngOnInit(): void {
    if (Storage.get(StorageKeys.customer)?.pathImage) {
      this.customerLogoPath = `${apiRootUrl}/customer/public?path=${Storage.get(StorageKeys.customer)?.pathImage}`;
    }
    this.idProject = Storage.get(StorageKeys.project).idProject;
    this.writtenBy = Storage.get(StorageKeys.project).writtenBy;
    this.projectStatus = PR_STATUS_PS_VERSION[`PR_STATUS_${Storage.get(StorageKeys.project).idProjectStatus}`];
    this.reason = Storage.get(StorageKeys.project).reason;
    if (Storage.get(StorageKeys.project).idProjectStatus < 9) {
      this.psMenuItems = [
        { routerLink: '/ps', title: 'Card' },
        { routerLink: '/ps/products', title: 'Products' },
        { routerLink: '/ps/markets', title: 'Markets' },
        { routerLink: '/ps/distribution', title: 'Distribution' },
        { routerLink: '/ps/competitors', title: 'Competitors' },
        { routerLink: '/ps/swot-usp', title: 'Swot&Usp' },
        { routerLink: '/ps/analysis', title: 'Analysis' },
        { routerLink: '/ps/strategy', title: 'Strategy' }
      ];
    }
    if (Storage.get(StorageKeys.project).idProjectStatus > 8) {
      this.rapMenuItems = {
        activitiesResumeSubMenuItems: [
          { routerLink: '/rap', title: 'MarketsAndChannels', fragment: 'marketsAndChannels' },
          { routerLink: '/rap', title: 'Contacts', fragment: 'contacts' },
          { routerLink: '/rap', title: 'OffersAndOrders', fragment: 'offersAndOrders' },
          { routerLink: '/rap', title: 'Performance', fragment: 'performance' },
        ],
        analysisSubMenuItems: [
          { routerLink: '/rap', title: 'MarketsBeingAnalysed', fragment: 'marketsBeingAnalysed' },
          { routerLink: '/rap', title: 'SwotUspAnalysis', fragment: 'swotUspAnalysis' },
          { routerLink: '/rap', title: 'SizingOfMarkets', fragment: 'sizingOfMarkets' },
          { routerLink: '/rap', title: 'CompetitorCompanyPositioning', fragment: 'competitorCompanyPositioning' },
        ],
        targetsAndActionPlanSubMenuItems: [
          { routerLink: '/rap', title: 'RevenueTargets', fragment: 'revenueTargets' },
          { routerLink: '/rap', title: 'ActionPlan', fragment: 'actionPlan' }
        ],
      };
    }
  }

  goToProjects(): void {
    this.router.navigate([`${Path.Welcome}/${Path.Projects}`]);
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(): void {
  //   const marketsHeight = this.el.nativeElement.querySelector('#marketsAndChannels').clientHeight;
  //   console.log(marketsHeight);
  // }

}
