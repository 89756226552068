import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Path } from '@app/core/enum/path';
import { Role } from '@app/core/enum/role';
import {
  PR_STATUS_PS_VERSION,
  PS_VERSION_PR_STATUS,
} from '@app/core/enum/status';
import { StorageKeys } from '@app/core/enum/storage';
import { ICustomer, ICustomers } from '@app/core/model/customer';
import { IProject, IProjectUser } from '@app/core/model/projects';
import { IUser } from '@app/core/model/user';
import { AuthService } from '@app/features/auth/services/auth.service';
import { ProjectService } from '@app/features/welcome/services/project.service';
import { Storage } from '@app/utility/storage';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DOCUMENT, Location, ViewportScroller } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  logoPath: any;
  showAccountDialog: boolean = false;
  showSupportDialog: boolean = false;
  showMenu: boolean = false;
  showPassword: boolean = false;
  userImage: string = '';
  user: IUser;
  userRole: string;
  links: any[];
  windowVariable = window;
  customers: ICustomer[];
  project: any;
  customerProjects: IProject[];
  projectStatusEnum: any = PR_STATUS_PS_VERSION;
  showProjectSelection: boolean = false;
  projectForm: FormGroup = this.formBuilder.group({
    customer: [{ value: '', disabled: true }],
    project: [''],
  });
  projectManager: IProjectUser;
  customersLoading: boolean;
  projectsLoading: boolean;
  customerName: string;
  analysisElem: HTMLElement;
  elementRect: DOMRect;
  navLinkToActivate: string;

  sections: any[] = [];
  current: string;
  fragment: any;

  @ViewChild('submenuTrigger') submenuTrigger: MatMenuTrigger;

  @HostListener('window:scroll', ['$event'])
  activateNavLinkOnScroll() {
    this.sections?.forEach((section: HTMLElement) => {
      const sectionTop = section.offsetTop;
      if (scrollY >= sectionTop - 120) {
        this.current = section.getAttribute('id');
      }
    });
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private projectService: ProjectService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    if (
      window.location.toString().includes('/ps') ||
      window.location.toString().includes('/rap')
    ) {
      setTimeout(() => {
        this.links?.forEach((link: any) => {
          this.sections.push(this.document.getElementById(link.fragment));
        });
        this.current = this.sections[0]?.getAttribute('id');
      }, 100);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe({
      next: (params: Params) => {
        this.project = {
          idCustomer: parseInt(params.idCustomer, 10),
          idProject: parseInt(params.idProject, 10),
          idProjectStatus: parseInt(params.idProjectStatus, 10),
        };
        setTimeout(() => {
          this.updateProjectDataAndLinks();
        });
      },
    });
    this.user = Storage.get(StorageKeys.user);
    if (this.user.roles.includes(Role.CUSTOMER)) {
      this.userRole = 'Customer';
    } else if (this.user.roles.includes(Role.LAB_MANAGER)) {
      this.userRole = 'DLManagerExternal';
    } else if (this.user.roles.includes(Role.LAB_MANAGER_INTERNAL)) {
      this.userRole = 'DLManagerInternal';
    } else if (this.user.roles.includes(Role.LAB_MANAGER_SUPERVISOR)) {
      this.userRole = 'TeamLeaderDLManager';
    };
    if (!this.user.roles.includes(Role.CUSTOMER)) {      
      this.customersLoading = true;
      this.projectService.getAllCustomersByUser().subscribe({
        next: (customers: ICustomers) => {
          this.customers = customers.customerActive;
          if (this.project) {
            this.getCurrentCustomerName();
          }
          this.customersLoading = false;
          this.projectForm.get('customer').enable();
        },
      });
    }
  }

  updateProjectDataAndLinks(): void {
    if (!window.location.toString().includes('/welcome/')) {
      this.showProjectSelection = true;
      this.projectForm.get('customer').setValue(this.project.idCustomer);
      this.getCustomerProjects(this.project.idCustomer);
    }
    if (window.location.toString().includes('/ps')) {
      const isDemo = Storage.get('project')?.isDemo;
      this.links = [
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Products&Services',
          fragment: 'products',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Markets',
          fragment: 'markets',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Distribution',
          fragment: isDemo ? 'distributionTrend' : 'distribution',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Competitors',
          fragment: 'competitors',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Swot&Usp',
          fragment: 'swot',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Analysis',
          fragment: 'analysis',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/ps`,
          label: 'Strategy',
          fragment: 'strategy',
        },
      ];
    } else if (window.location.toString().includes('/rap')) {
      this.links = [
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/rap`,
          label: 'ActivitiesResume',
          fragment: 'resume',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/rap`,
          label: 'Analysis',
          fragment: 'analysis',
        },
        {
          path: `/customer/${this.project.idCustomer}/project/${this.project.idProject}/status/${this.project.idProjectStatus}/rap`,
          label: 'TargetsAndActionPlan',
          fragment: 'targets',
        },
      ];
    }
  }

  openCloseMenu(): void {
    if (!this.showMenu) {
      this.showMenu = true;
      this.document.body.classList.add('block-scroll');
    } else {
      this.showMenu = false;
      this.document.body.classList.remove('block-scroll');
    }
  }

  getCurrentCustomerName() {
    this.customers.forEach((customer) => {
      if (customer.idCustomer === this.project.idCustomer) {
        this.customerName = customer.businessName;
      }
    });
  }

  getCustomerProjects(customerId: number): void {
    this.projectsLoading = true;
    this.projectForm.get('project').setValue('loading');
    this.projectForm.get('project').disable();
    this.customerProjects = [];
    this.projectService.getAllProjectsByCustomer(customerId).subscribe({
      next: (projects: IProject[]) => {
        this.customerProjects = projects;
        this.projectsLoading = false;
        this.projectForm.get('project').enable();
        // get current project for project html select
        this.customerProjects.forEach((customerProject) => {
          if (customerProject.idProject === this.project.idProject) {
            customerProject.projectHistoryList.forEach((projectHistory) => {
              if (
                projectHistory.idProjectStatus === this.project.idProjectStatus
              ) {
                this.projectForm.get('project').setValue(projectHistory);
              }
            });
          }
        });
      },
    });
  }

  goTo(page: string): void {
    // TODO: applicare i link delle pagine come profilo e supporto
    console.log(page);
  }

  goToWelcomePage(): void {
    this.router.navigate([`${Path.Welcome}/${Path.Projects}`]);
  }

  logout(): void {
    this.authService.logout();
  }

  onCustomerChange(): void {
    this.getCustomerProjects(this.projectForm.get('customer').value);
    this.getCurrentCustomerName();
  }

  onProjectChange(): void {
    const project = this.projectForm.get('project').value;
    // get project manager
    this.customerProjects.forEach((customerProject) => {
      if (customerProject.idProject === project.idProject) {
        customerProject.projectHistoryList.forEach((projectHistory) => {
          if (projectHistory.idProjectStatus === project.idProjectStatus) {
            this.projectManager = customerProject.projectManager;
          }
        });
      }
    });
    this.handleProject(project);
  }

  handleProject(selectedProject: IProject): void {
    const project = this.customerProjects
      .find(project => project.idProject === selectedProject.idProject);
    Storage.set("project", {
      isPublished: selectedProject?.isPublished,
      isDemo: project?.type === 2
    });
    // Remove scroll class
    this.document
      .getElementsByTagName('html')[0]
      .classList.remove('smooth-scroll');
    // Remove block scroll class
    this.document.body.classList.remove('block-scroll');
    // Id of the current customer
    const idCustomer: number = parseInt(
      this.projectForm.get('customer').value,
      10
    );
    // Set selected project info
    this.project = {
      idCustomer,
      idProject: selectedProject.idProject,
      idProjectStatus: selectedProject.idProjectStatus,
    };
    // Check project status and navigate to it
    if (selectedProject?.idProjectStatus === PS_VERSION_PR_STATUS.PS) {
      this.router.navigate(
        [
          `customer/${idCustomer}/project/${selectedProject?.idProject}/status/${selectedProject?.idProjectStatus}/ps`,
        ],
        {
          fragment: 'products',
        }
      );
    } else {
      this.router.navigate(
        [
          `customer/${idCustomer}/project/${selectedProject?.idProject}/status/${selectedProject?.idProjectStatus}/rap`,
        ],
        { fragment: 'resume' }
      );
    }
    this.submenuTrigger.closeMenu();
    this.getCurrentCustomerName();
  }

  scrollToSmoothly(fragment: string): void {
    if (
      window.location.toString().includes('/rap') ||
      window.location.toString().includes('/ps')
    ) {
      this.document
        .getElementsByTagName('html')[0]
        .setAttribute('class', 'smooth-scroll');
    } else {
      this.document
        .getElementsByTagName('html')[0]
        .classList.remove('smooth-scroll');
    }
    this.viewportScroller.setOffset([0, 113]);
    // this.viewportScroller.scrollToAnchor(fragment);
  }
}
