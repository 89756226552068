export enum Role {
    ADMINISTRATOR_ROLE = 'ADMIN',
    AGENT_ROLE = 'AGENT',
    DIRECTION_ROLE = 'DIRECTION',
    AGENT_SUPERVISOR = 'AGENT_SUPERVISOR',
    LAB_MANAGER = 'LAB_MANAGER',
    LAB_MANAGER_SUPERVISOR = 'LAB_MANAGER_SUPERVISOR',
    LAB_MANAGER_INTERNAL = 'LAB_MANAGER_INTERNAL',
    CUSTOMER = 'CUSTOMER',
}
