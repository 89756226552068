<table
  *ngIf="!loader && data?.length"
  matSort
  (matSortChange)="sortData($event)"
  class="test-table"
  [ngClass]="dataColor"
  cellpadding="10"
>
  <thead>
    <tr>
      <ng-container *ngFor="let column of columns; let i = index">
        <th 
          *ngIf="!column.hide"
          [mat-sort-header]="column.name"
          [ngStyle]="{ width: column.width + '%' }"
          [ngClass]="{
            'center-align': column?.align === 'center',
            'right-align': column?.align === 'right'
          }"
        >
          <div>
            <span>{{ column.translatedName }}</span>
            <button
              *ngIf="column?.legend"
              type="button"
              [matMenuTriggerFor]="legend"
              class="only-icon-button primary"
              (click)="$event.stopPropagation()"
              style="margin-left: 5px"
            >
              <mat-icon style="vertical-align: middle">
                info
              </mat-icon>
            </button>
            <mat-menu #legend="matMenu">
              <div
                *ngFor="let legend of column.legend"
                style="padding: 0 10px"
              >
                {{ legend | translate }}
              </div>
            </mat-menu>
          </div>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let row of data; let colorIndex = index"
      [ngClass]="{
        'new-element-badge': rowBadgeNewElements && !row.alreadyRead
      }"
    >
      <ng-container *ngFor="let column of columns">
        <td *ngIf="column.name === 'checkbox'" [class.td-right-aligned]="column?.align === 'right'">
          <mat-checkbox
            color="primary"
            [disabled]="columnCheckbox?.disabled(row)"
            [checked]="columnCheckbox?.checked(row)"
            (change)="columnCheckbox?.change($event, row)"
          >
            {{ columnCheckbox?.label }}
          </mat-checkbox>
        </td>
        <td *ngIf="column.name === 'channels'" [class.td-right-aligned]="column?.align === 'right'">
          <strong>{{ row.distributionDesc || "--" }}</strong>
          <br />
          {{ row.distributionSpec || "--" }}
        </td>
        <td *ngIf="column.name === 'presenceMarketType'" [class.td-right-aligned]="column?.align === 'right'">
          <div *ngIf="row?.idMarketType === 1 && !row?.toIncrease">
            {{ "GENERAL.OnlyOfPresence" | translate }}
          </div>
          <div *ngIf="row?.idMarketType === 1 && row?.toIncrease">
            {{ "GENERAL.OfPresenceToIncrease" | translate }}
          </div>
        </td>
        <td *ngIf="column.name === 'potentialMarketType'" [class.td-right-aligned]="column?.align === 'right'">
          <div *ngIf="row?.idMarketType === 3">
            {{ "GENERAL.OfActualHighPotential" | translate }}
          </div>
          <div *ngIf="row?.idMarketType === 4">
            {{ "GENERAL.OfFutureHighPotential" | translate }}
          </div>
        </td>
        <td
          *ngIf="
            column.name !== 'channels' &&
            column.name !== 'buttons' &&
            column.name !== 'text' &&
            column.name !== 'checkbox' &&
            column.name !== 'multiRowContact' &&
            column.name !== 'buttonWithHeading' &&
            column.name !== 'presenceMarketType' &&
            column.name !== 'potentialMarketType' &&
            !column.hide
          "
          [ngStyle]="{
            'font-weight': column?.bold ? '600' : 'inherit',
            'justify-content': column?.align || 'flex-start',
            'text-align':
              column?.align
                ? column.align === 'center'
                  ? 'center'
                  : 'right'
                : 'left'
          }"
          [ngClass]="{
            'td-chart-color': column?.chartColor,
            'td-flag': column?.name === 'country',
            'td-right-aligned': column?.align === 'right'
          }"
        >
          <div *ngIf="column.name === 'country'" class="flag">
            <img src="{{ 'assets/flags/' + row.idCountry + '.svg' }}" />
          </div>
          <div
            *ngIf="column?.chartColor"
            class="chart-color"
            [ngStyle]="{
              'background-color':
                chartColors[colorIndex + pageIndexForChartColor * 5]
            }"
          ></div>
          <span
            *ngIf="
              column?.type !== 'currency' &&
              column?.type !== 'date' &&
              column?.type !== 'number' &&
              column?.type !== 'innerHTML'
            "
          >
            {{ row[column?.name] || "--" }}
          </span>
          <span *ngIf="column?.type === 'currency'">
            {{
              (row[column?.name]
                | currency
                  : ""
                  : "symbol"
                  : "1.0-2"
                  : undefined) || "--"
            }}
          </span>
          <span *ngIf="column?.type === 'date'">
            {{ row[column?.name] | date: "dd/MM/yyyy - hh:mm" }}
          </span>
          <span *ngIf="column?.type === 'number'">
            {{ (row[column?.name] | number: "1.0-2":undefined) || "--" }}
          </span>
          <span *ngIf="column?.suffix">
            {{ column.suffix }}
          </span>
          <span
            *ngIf="column?.type === 'innerHTML'"
            [innerHTML]="row[column.name]"
          ></span>
          <!-- <svg-icon
            *ngIf="checkIfIcon(row, column)"
            [name]="row[column.name]"
            class="w20"
          ></svg-icon> -->
        </td>
        <td *ngIf="column.name === 'multiRowContact'" [class.td-right-aligned]="column?.align === 'right'">
          <div>
            <div>
              <span class="fw-700">{{ row[column.name] }}</span>
            </div>
            <div>
              <span>{{ row.multiRowContactPhone }}</span>
            </div>
            <div>
              <span>{{ row.multiRowContactEmail }}</span>
            </div>
          </div>
        </td>
        <td
          *ngIf="column?.name === 'buttonWithHeading'"
          class="d-flex"
          [ngStyle]="{
            'justify-content': column?.align || 'flex-start'
          }"
          [class.td-right-aligned]="column?.align === 'right'"
        >
          <ng-container *ngFor="let button of columnsButtonsWithHeading">
            <button
              *ngIf="!button.subMenu"
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              (click)="button?.clickFunction(row)"
            >
              <!-- <svg-icon [name]="button.icon" class="w20"></svg-icon> -->
            </button>
            <button
              *ngIf="
                (button?.subMenu?.buttons &&
                  button?.subMenu?.buttons?.length) ||
                (button?.subMenu && !button?.subMenu?.buttons)
              "
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              [matMenuTriggerFor]="customSubMenu"
            >
              <!-- <svg-icon [name]="button.icon" class="w20"></svg-icon> -->
            </button>
            <mat-menu #customSubMenu="matMenu">
              <ng-container *ngIf="button?.subMenu?.buttons?.length">
                <button
                  mat-menu-item
                  *ngFor="let subButton of button?.subMenu?.buttons"
                  (click)="subButton.clickFunction(row)"
                >
                  <!-- <svg-icon
                    *ngIf="subButton?.icon"
                    [name]="button.icon"
                    [ngClass]="button?.class"
                  ></svg-icon> -->
                  <span *ngIf="subButton?.text">
                    {{ subButton?.text }}
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="button?.subMenu?.key">
                <button
                  mat-menu-item
                  *ngFor="let subButtonElaborated of row[button?.subMenu?.key]"
                  (click)="button?.subMenu?.clickFunction(subButtonElaborated)"
                >
                  <!-- <img
                *ngIf="button?.subMenuElaboratorOptions?.icon"
                src="{{ '/assets/icons/' + button?.subMenuElaboratorOptions?.icon + '.svg' }}"
              > -->
                  <span *ngIf="subButtonElaborated?.name">
                    {{ subButtonElaborated?.name }}
                  </span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </td>
        <td *ngIf="column.name === 'buttons'" [class.td-right-aligned]="column?.align === 'right'">
          <div class="buttons-td">
            <ng-container *ngFor="let button of columnsButtons">
              <div class="button-wrapper" [matTooltip]="button?.tooltip">
                <button
                  *ngIf="!button.subMenu"
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  (click)="button?.clickFunction(row)"
                >
                  <!-- <svg-icon [name]="button.icon" class="w20"></svg-icon> -->
                </button>
                <button
                  *ngIf="
                    (button?.subMenu?.buttons &&
                      button?.subMenu?.buttons?.length) ||
                    (button?.subMenu && !button?.subMenu?.buttons)
                  "
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  [matMenuTriggerFor]="customSubMenu"
                >
                  <!-- <svg-icon [name]="button.icon" class="w20"></svg-icon> -->
                </button>
                <mat-menu #customSubMenu="matMenu">
                  <ng-container *ngIf="button?.subMenu?.buttons?.length">
                    <button
                      mat-menu-item
                      *ngFor="let subButton of button?.subMenu?.buttons"
                      (click)="subButton.clickFunction(row)"
                    >
                      <!-- <svg-icon
                        *ngIf="subButton?.icon"
                        [name]="button.icon"
                        [ngClass]="button?.class"
                      ></svg-icon> -->
                      <span *ngIf="subButton?.text">
                        {{ subButton?.text }}
                      </span>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="button?.subMenu?.key">
                    <button
                      mat-menu-item
                      *ngFor="
                        let subButtonElaborated of row[button?.subMenu?.key]
                      "
                      (click)="
                        button?.subMenu?.clickFunction(subButtonElaborated)
                      "
                    >
                      <!-- <img
                      *ngIf="button?.subMenuElaboratorOptions?.icon"
                      src="{{ '/assets/icons/' + button?.subMenuElaboratorOptions?.icon + '.svg' }}"
                    > -->
                      <span *ngIf="subButtonElaborated?.name">
                        {{ subButtonElaborated?.name }}
                      </span>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
            </ng-container>
          </div>
        </td>
        <td *ngIf="column.name === 'text'" [class.td-right-aligned]="column?.align === 'right'">
          <span [ngClass]="column?.class">
            {{ row[column.name] }}
          </span>
        </td>
      </ng-container>
    </tr>
  </tbody>
  <tfoot *ngIf="total != undefined">
    <tr class="total-row">
      <td class="total">
        {{ "GENERAL.TableTotal" | translate | uppercase }}
      </td>
      <ng-container *ngFor="let column of columns; let i = index">
        <td
          *ngIf="
            i != 0 &&
            column.name !== 'buttons' &&
            column.name !== 'text' &&
            column.name !== 'checkbox' &&
            column.name !== 'multiRowContact' &&
            column.name !== 'buttonWithHeading' &&
            !column.hide
          "
          [ngStyle]="{
            'justify-content': column?.align || 'flex-start',
            'text-align':
              column?.align
                ? column.align === 'center'
                  ? 'center'
                  : 'right'
                : 'left'
          }"
          [class.td-right-aligned]="column?.align === 'right'"
        >
          <span
            *ngIf="
              column?.type !== 'currency' &&
              column?.type !== 'date' &&
              column?.type !== 'number' &&
              column?.type !== 'innerHTML'
            "
          >
            {{ total[column?.name] || "--" }}
          </span>
          <span *ngIf="column?.type === 'currency'">
            {{
              (total[column?.name]
                | currency
                  : ""
                  : "symbol"
                  : "1.0-2"
                  : undefined) || "--"
            }}
          </span>
          <span *ngIf="column?.type === 'date'">
            {{ total[column?.name] | date: "dd/MM/yyyy - hh:mm" }}
          </span>
          <span *ngIf="column?.type === 'number'">
            {{ (total[column?.name] | number: "1.0-2":undefined) || "--" }}
          </span>
          <span *ngIf="column?.suffix">
            {{ column.suffix }}
          </span>
          <span
            *ngIf="column?.type === 'innerHTML'"
            [innerHTML]="total[column.name]"
          ></span>
        </td>
        <td *ngIf="i != 0 && column.name === 'multiRowContact'" [class.td-right-aligned]="column?.align === 'right'">
          <div>
            <div>
              <span class="fw-700">{{ total[column.name] }}</span>
            </div>
            <div>
              <!-- <span>{{ total?.multiRowContactPhone }}</span> -->
            </div>
            <div>
              <!-- <span>{{ total?.multiRowContactEmail }}</span> -->
            </div>
          </div>
        </td>
        <td
          *ngIf="i != 0 && column?.name === 'buttonWithHeading'"
          class="d-flex"
          [ngStyle]="{
            'justify-content': column?.align || 'flex-start'
          }"
          [class.td-right-aligned]="column?.align === 'right'"
        >
          <ng-container *ngFor="let button of columnsButtonsWithHeading">
            <button
              *ngIf="!button.subMenu"
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              (click)="button?.clickFunction(total)"
            ></button>
            <button
              *ngIf="
                (button?.subMenu?.buttons &&
                  button?.subMenu?.buttons?.length) ||
                (button?.subMenu && !button?.subMenu?.buttons)
              "
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              [matMenuTriggerFor]="customSubMenu"
            ></button>
            <mat-menu #customSubMenu="matMenu">
              <ng-container *ngIf="button?.subMenu?.buttons?.length">
                <button
                  mat-menu-item
                  *ngFor="let subButton of button?.subMenu?.buttons"
                  (click)="subButton.clickFunction(total)"
                >
                  <span *ngIf="subButton?.text">
                    {{ subButton?.text }}
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="button?.subMenu?.key">
                <button
                  mat-menu-item
                  *ngFor="
                    let subButtonElaborated of total[button?.subMenu?.key]
                  "
                  (click)="button?.subMenu?.clickFunction(subButtonElaborated)"
                >
                  <span *ngIf="subButtonElaborated?.name">
                    {{ subButtonElaborated?.name }}
                  </span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </td>
        <td *ngIf="i != 0 && column.name === 'buttons'" [class.td-right-aligned]="column?.align === 'right'">
          <div class="buttons-td">
            <ng-container *ngFor="let button of columnsButtons">
              <div class="button-wrapper" [matTooltip]="button?.tooltip">
                <button
                  *ngIf="!button.subMenu"
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  (click)="button?.clickFunction(total)"
                ></button>
                <button
                  *ngIf="
                    (button?.subMenu?.buttons &&
                      button?.subMenu?.buttons?.length) ||
                    (button?.subMenu && !button?.subMenu?.buttons)
                  "
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  [matMenuTriggerFor]="customSubMenu"
                ></button>
                <mat-menu #customSubMenu="matMenu">
                  <ng-container *ngIf="button?.subMenu?.buttons?.length">
                    <button
                      mat-menu-item
                      *ngFor="let subButton of button?.subMenu?.buttons"
                      (click)="subButton.clickFunction(total)"
                    >
                      <span *ngIf="subButton?.text">
                        {{ subButton?.text }}
                      </span>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="button?.subMenu?.key">
                    <button
                      mat-menu-item
                      *ngFor="
                        let subButtonElaborated of total[button?.subMenu?.key]
                      "
                      (click)="
                        button?.subMenu?.clickFunction(subButtonElaborated)
                      "
                    >
                      <span *ngIf="subButtonElaborated?.name">
                        {{ subButtonElaborated?.name }}
                      </span>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
            </ng-container>
          </div>
        </td>
        <td *ngIf="i != 0 && column.name === 'text'" [class.td-right-aligned]="column?.align === 'right'">
          <span [ngClass]="column?.class">
            {{ total[column.name] }}
          </span>
        </td>
      </ng-container>
    </tr>
  </tfoot>
</table>
<mat-paginator
  #paginator
  [ngStyle]="{
    display: !loader && oldData?.length > pageSize ? 'block' : 'none'
  }"
  [length]="oldData?.length"
  [pageSize]="pageSize"
  (page)="pageChanged($event)"
  [pageSizeOptions]="paginatorOptions.pageSizeOptions"
  [pageIndex]="paginatorOptions.pageIndex"
>
</mat-paginator>
<div *ngIf="loader" class="skeleton-loader">
  <div class="header"></div>
  <div class="body-row"></div>
  <div class="body-row"></div>
  <div class="body-row"></div>
</div>
<div *ngIf="!data?.length && !loader" class="no-data">
  <span>{{ "GENERAL.NoDataAvailable" | translate }}</span>
</div>
