<div class="d-flex align-items-center mt-20">
  <div class="sider-arrow" (click)="goToProjects()">
    <i nz-icon nzType="arrow-left" class="arrow-icon" nzTheme="outline"></i>
  </div>
  <div class="ml-20">
    <h3 class="cl-app">PR-{{ idProject }}</h3>
  </div>
  <div class="ml-20">
    <span class="fs-14 fw-700">{{ projectStatus }}</span>
  </div>
</div>
<div class="ml-70">
  <h6 style="color: #868686;">{{ reason || 'ME' }} - {{ writtenBy }}</h6>
</div>
<ul *ngIf="psMenuItems?.length" class="mt-40">
  <li
    *ngFor="let menuItem of psMenuItems"
    class="side-menu-item"
    [routerLink]="menuItem.routerLink"
    routerLinkActive="active-item"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <div nz-row>
      <div nz-col nzOffset="3" nzSpan="21">
        <h4>{{ 'PS.' + menuItem.title | translate | uppercase }}</h4>
      </div>
    </div>
  </li>
</ul>
<ul *ngIf="rapMenuItems" class="mt-40">
  <li class="side-menu-item no-cursor-pointer">
    <div
      nz-row
      class="align-items-center cursor-pointer"
      (click)="
        expandMenuItem[0] = !expandMenuItem[0];
        expandMenuItem[1] = false;
        expandMenuItem[2] = false;
      "
    >
      <div nz-col nzOffset="3" nzSpan="18">
        <h4 [ngClass]="expandMenuItem[0] ? 'cl-app' : ''">{{ 'RAP.ActivitiesResume' | translate | uppercase }}</h4>
      </div>
      <div nz-col nzSpan="1">
        <i
          nz-icon
          [nzType]="expandMenuItem[0] ? 'caret-up' : 'caret-down'"
          nzTheme="outline"
        ></i>
      </div>
    </div>
    <ul *ngIf="expandMenuItem[0]" class="mt-20">
      <li
        class="sub-menu-item"
        *ngFor="let subMenuItem of rapMenuItems.activitiesResumeSubMenuItems"
        [routerLink]="subMenuItem.routerLink"
        [fragment]="subMenuItem.fragment"
        routerLinkActive="active-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div nz-row>
          <h6
            nz-col
            nzOffset="3"
            nzSpan="21"
            [ngClass]="
              windowVariable.location.hash === '#' + subMenuItem.fragment ?
              'cl-app' : ''
            "
          >{{ 'RAP.' + subMenuItem.title | translate }}</h6>
        </div>
      </li>
    </ul>
  </li>
  <li class="side-menu-item no-cursor-pointer">
    <div
      nz-row
      class="align-items-center cursor-pointer"
      (click)="
        expandMenuItem[1] = !expandMenuItem[1];
        expandMenuItem[0] = false;
        expandMenuItem[2] = false;
      "
    >
      <div nz-col nzOffset="3" nzSpan="18">
        <h4 [ngClass]="expandMenuItem[1] ? 'cl-app' : ''">{{ 'RAP.Analysis' | translate | uppercase }}</h4>
      </div>
      <div nz-col nzSpan="1">
        <i
          nz-icon
          [nzType]="expandMenuItem[1] ? 'caret-up' : 'caret-down'"
          nzTheme="outline"
        ></i>
      </div>
    </div>
    <ul *ngIf="expandMenuItem[1]" class="mt-20">
      <li
        class="sub-menu-item"
        *ngFor="let subMenuItem of rapMenuItems.analysisSubMenuItems"
        [routerLink]="subMenuItem.routerLink"
        [fragment]="subMenuItem.fragment"
        routerLinkActive="active-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div nz-row>
          <h6
            nz-col
            nzOffset="3"
            nzSpan="21"
            [ngClass]="
              windowVariable.location.hash === '#' + subMenuItem.fragment ?
              'cl-app' : ''
            "
          >{{ 'RAP.' + subMenuItem.title | translate }}</h6>
        </div>
      </li>
    </ul>
  </li>
  <li class="side-menu-item no-cursor-pointer">
    <div
      nz-row
      class="align-items-center cursor-pointer"
      (click)="
        expandMenuItem[2] = !expandMenuItem[2];
        expandMenuItem[0] = false;
        expandMenuItem[1] = false;
      "
    >
      <div nz-col nzOffset="3" nzSpan="18">
        <h4 [ngClass]="expandMenuItem[2] ? 'cl-app' : ''">{{ 'RAP.TargetsAndActionPlan' | translate | uppercase }}</h4>
      </div>
      <div nz-col nzSpan="1">
        <i
          nz-icon
          [nzType]="expandMenuItem[2] ? 'caret-up' : 'caret-down'"
          nzTheme="outline"
        ></i>
      </div>
    </div>
    <ul *ngIf="expandMenuItem[2]" class="mt-20">
      <li
        class="sub-menu-item"
        *ngFor="let subMenuItem of rapMenuItems.targetsAndActionPlanSubMenuItems"
        [routerLink]="subMenuItem.routerLink"
        [fragment]="subMenuItem.fragment"
        routerLinkActive="active-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div nz-row>
          <h6
            nz-col
            nzOffset="3"
            nzSpan="21"
            [ngClass]="
              windowVariable.location.hash === '#' + subMenuItem.fragment ?
              'cl-app' : ''
            "
          >{{ 'RAP.' + subMenuItem.title | translate }}</h6>
        </div>
      </li>
    </ul>
  </li>
</ul>
<div class="mt-50 ml-30 logo-container">
  <img class="d-like-logo" src="assets/logos/D_LIKE_LOGO.svg" />
</div>
<div class="mt-10 ml-30">
  <span class="text-light">FOR</span>
</div>
<div class="customer-logo mt-10 ml-30">
  <div class="default-logo" *ngIf="!customerLogoPath">
    <span class="fw-700 fs-14 cl-app">{{ 'PS.CompanyLogo' | translate | uppercase }}</span>
  </div>
  <img *ngIf="customerLogoPath" [src]="customerLogoPath" />
</div>
