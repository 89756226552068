export enum Path {
  // General
  Welcome = 'welcome',
  Auth = 'auth',
  PS = 'customer/:idCustomer/project/:idProject/status/:idProjectStatus/ps',
  RAP = 'customer/:idCustomer/project/:idProject/status/:idProjectStatus/rap',
  NotFound = 'not-found',
  Unauthorized = 'unauthorized',

  // Auth
  SignIn = 'sign-in',
  SignUp = 'signup',
  ResetPassword = 'reset-password',

  // Welcome
  Projects = 'projects',

  // PS
  Products = 'products',
  Markets = 'markets',
  SwotUsp = 'swot-usp',
  Distribution = 'distribution',
  Competitors = 'competitors',
  Analysis = 'analysis',
  Strategy = 'strategy',

  // RAP
  RapAnalysis = 'rap-analysis',
  TargetsActionPlans = 'targets-action-plans',
}
